import React from 'react';
import CurriculumSelector from '../../components/CurriculumSelector';
import DigitalLessonBundle from '../../components/DigitalLessonBundle';
import { Column, Container, Row } from '../../components/Grid';
import Image from '../../components/Image';
import Layout from '../../components/Layout';
import Resource from '../../components/Resource';
import Section from '../../components/Section';
import Video from '../../components/Video';
import Action from '../../components/Resource/Action';

const IndexPage = ({ data }) => (
  <Layout title="Relationship between Energy and Forces">
    <Section>
      <Container>
        <Row>
          <Column size={11}>
            <h1 className="page-header">
              THE RELATIONSHIP BETWEEN<br></br>ENERGY AND FORCES
            </h1>
          </Column>
        </Row>
        <Row>
          <Column size={8}>
            <Video
              poster="forces-of-motion-placeholder.png"
              guidSrc="e4e641c1-e993-48bc-896c-5f1b6c6dabe2"
              title="The Relationship Between Energy and Forces"
            />
          </Column>
          <Column size={4}>
            <p>
              Forces such as gravity and magnetism transmit energy across space
              from one object to another and doing everything from attracting
              electrons to protons to keeping nearly million-pound jumbo jets in
              the air. Learn how we use sensors to measure these forces and
              better understand our world.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    {/************** VTS GUIDE **************/}
    <Section>
      <Container>
        <Row className="educator-guide-forces">
          <Column size={4}>
            <Image filename="resources-image-EdGuide.png" />
          </Column>
          <Column size={6} className="educator-guide-column">
            <h3>Video Topic Series Guide</h3>
            <p>
              The Caterpillar Foundation Video Series Guide for high school
              physics is a standards-aligned curriculum of engaging videos and
              hands-on activities designed to bring the world of STEM and
              Industry 4.0 into the classroom. Students learn the fundamentals
              of physical laws and how to problem solve by tackling real-world
              challenges in class and after-school sessions. Through a process
              of investigation, team discussion, and hands-on experiences,
              students will advance theory into applications they can see and
              touch.
            </p>
          </Column>
          <Column size={2} className="bottom-button">
            <div className="resource__actions resource__actions--aligned">
              <Action
                label="Download"
                dropdown={true}
                dropdownItemClassname="vts__dropdown-items"
                actions={[
                  {
                    label: 'English',
                    download: '/pdfs/Caterpillar-VTS-EdGuide.pdf',
                    metadata: '.PDF, 410KB',
                  },
                  {
                    label: 'Spanish',
                    download: '/pdfs/Caterpillar-VTS-EdGuide_ES.pdf',
                    metadata: '.PDF, 418KB',
                  },
                  {
                    label: 'French',
                    download: '/pdfs/Caterpillar-VTS-EdGuide_FR.pdf',
                    metadata: '.PDF, 436KB',
                  },
                ]}
              />
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/************** VTS ACTIVITIES **************/}
    <Section>
      <Container>
        <Row>
          <Column size={12}>
            <h2>Hands-On Classroom Activities</h2>
          </Column>
        </Row>
        <Row>
          <Column size={9}>
            <p>
              Discover how the Laws of Motion play a part in every single thing
              we do with these simple, easy-to-implement activations aligned to
              education standards. Students will take on a series of creative
              STEM challenges in which they put the power of force and motion
              into action.
            </p>
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <Resource
              img={'resources-energyandforces-image-activity1@2x.png'}
              subject={'Classroom Activity'}
              title={'Mixed Reality Headsets'}
              audience="How can virtual reality be used to change the world?"
              description="In this activity, student teams select a professional discipline they’re interested in and ideate a way that virtual or mixed reality headsets could improve that field. "
              actions={[
                {
                  label: 'Download',
                  dropdown: true,
                  actions: [
                    {
                      label: 'English',
                      download:
                        '/pdfs/Caterpillar-ClassroomActivity-MixedRealityHeadsets.pdf',
                      metadata: '.PDF, 475KB',
                    },
                    {
                      label: 'Spanish',
                      download:
                        '/pdfs/Caterpillar-ClassroomActivity-MixedRealityHeadsets-ES.pdf',
                      metadata: '.PDF, 615KB',
                    },
                    {
                      label: 'French',
                      download:
                        '/pdfs/Caterpillar-ClassroomActivity-MixedRealityHeadsets-FR.pdf',
                      metadata: '.PDF, 636KB',
                    },
                  ],
                },
              ]}
            />
          </Column>
          <Column size={4}>
            <Resource
              img={'resources-energyandforces-image-activity2@2x.png'}
              subject={'Classroom Activity'}
              title={'Virtual Gravity'}
              audience="What is gravitational force and how do you calculate it?"
              description="Students learn about Newton’s Law of Universal Gravitation and apply their knowledge to design and storyboard a lifelike mixed reality experience simulating the gravity of a selected planet or moon. "
              actions={[
                {
                  label: 'Download',
                  dropdown: true,
                  actions: [
                    {
                      label: 'English',
                      download:
                        '/pdfs/Caterpillar-ClassroomActivity-VirtualGravity.pdf',
                      metadata: '.PDF, 519KB',
                    },
                    {
                      label: 'Spanish',
                      download:
                        '/pdfs/Caterpillar-ClassroomActivity-VirtualGravity-ES.pdf',
                      metadata: '.PDF, 813KB',
                    },
                    {
                      label: 'French',
                      download:
                        '/pdfs/Caterpillar-ClassroomActivity-VirtualGravity-FR.pdf',
                      metadata: '.PDF, 841KB',
                    },
                  ],
                },
              ]}
            />
          </Column>
          <Column size={4}>
            <Resource
              img={'resources-energyandforces-image-activity3@2x.png'}
              subject={'Classroom Activity'}
              title={'World of Forces'}
              audience="What if the laws of physics were different?"
              description="The formal laws of physics take a holiday in this activity as students develop a videogame using forcefields to drive the gameplay. Students will create illustrations or storyboards to explain the game setting, characters, and goals using magnetic, electrical, and gravitational forcefields. "
              actions={[
                {
                  label: 'Download',
                  dropdown: true,
                  actions: [
                    {
                      label: 'English',
                      download:
                        '/pdfs/Caterpillar-ClassroomActivity-WorldofForceFields.pdf',
                      metadata: '.PDF, 478KB',
                    },
                    {
                      label: 'Spanish',
                      download:
                        '/pdfs/Caterpillar-ClassroomActivity-WorldofForceFields-ES.pdf',
                      metadata: '.PDF, 649KB',
                    },
                    {
                      label: 'French',
                      download:
                        '/pdfs/Caterpillar-ClassroomActivity-WorldofForceFields-FR.pdf',
                      metadata: '.PDF, 667KB',
                    },
                  ],
                },
              ]}
            />
          </Column>
        </Row>
      </Container>
    </Section>
    {/************** QUOTE **************/}
    <Section>
      <Container>
        <Row>
          <Column size="12" className="forces-quote">
            <Image
              filename="resources-graphic-quotemark.svg"
              className="start-slash"
            />
            <p className="forces-quote-text">
              AI, big data, machine learning, and automation will usher in a new
              and novel world full of interesting careers that don’t even exist
              today.
            </p>
            <Image
              filename="resources-graphic-quotemark.svg"
              className="end-slash"
            />
            <p className="forces-quote-author">– Kat, Host</p>
          </Column>
        </Row>
      </Container>
    </Section>
    {/************** AFTERSCHOOL ACTIVITIES **************/}
    <Section>
      <Container>
        <Row className="section-image-header">
          <Column size={6}>
            <h2>After School Activity</h2>
          </Column>
          <Column size={6}>
            <Image filename="resources-graphic-lineart-ASactivity.svg" />
          </Column>
        </Row>
        <Row>
          <Column size={9}>
            <p>
              Explore the ways that the Laws of Motion can be harnessed in
              students’ everyday lives with a series of engaging activities that
              are easy to implement in any environment.
            </p>
          </Column>
        </Row>
        <Row>
          <DigitalLessonBundle
            resource1={
              <Resource
                img={'resources-energyandforces-image-ASactivity@2x.png'}
                subject={'Afterschool Activity'}
                title={'Sensing and Communication'}
                className="magnus-effect"
                description="Uncover the mysteries of the Magnus Effect as students learn how this unique phenomenon of motion is used to achieve amazing effects in sports, engineering and beyond. Kickstart creative problem-solving with a series of hands-on student activations that put the fundamentals of motion into action."
                actions={[
                  {
                    label: 'Download',
                    dropdown: true,
                    actions: [
                      {
                        label: 'English',
                        download:
                          '/pdfs/Caterpillar-AfterschoolExtension-ReadySetGoal.pdf',
                        metadata: '.PDF, 509KB',
                      },
                      {
                        label: 'Spanish',
                        download:
                          '/pdfs/Caterpillar-AfterschoolActivity-SensingAndCommunicating-ES.pdf',
                        metadata: '.PDF, 1.3MB',
                      },
                      {
                        label: 'French',
                        download:
                          '/pdfs/Caterpillar-AfterschoolActivity-SensingAndCommunicating-FR.pdf',
                        metadata: '.PDF, 1.3MB',
                      },
                    ],
                  },
                ]}
              />
            }
            resource2={
              <Resource
                img={'global-image-ASFacilitatorguide@2x.png'}
                title={'Facilitator Guide'}
                className="facilitator-guide"
                description="Students learn the fundamentals of physics and how to solve problems by tackling real-world challenges in out of class and after-school sessions. Through a process of investigation, team discussion, and hands-on experiences, students will advance theory into applications they can see and touch."
                actions={[
                  {
                    label: 'Download',
                    dropdown: true,
                    actions: [
                      {
                        label: 'English',
                        download: '/pdfs/Caterpillar-AfterschoolGuide.pdf',
                        metadata: '.PDF, 606KB',
                      },
                      {
                        label: 'Spanish',
                        download: '/pdfs/Caterpillar-AfterschoolGuide-ES.pdf',
                        metadata: '.PDF, 718KB',
                      },
                      {
                        label: 'French',
                        download: '/pdfs/Caterpillar-AfterschoolGuide-FR.pdf',
                        metadata: '.PDF, 736KB',
                      },
                    ],
                  },
                ]}
              />
            }
            className="dlb-row"
          />
        </Row>
      </Container>
    </Section>
    {/************** CURRICULUM SELECTOR **************/}
    <Section>
      <Container>
        <CurriculumSelector />
      </Container>
    </Section>
    {/************** TESTIMONIAL **************/}
    <Section>
      <Container>
        <Row>
          <Column>
            <Image filename="resources-image-testimonial.png" />
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default IndexPage;
